.comment-form {
    margin-bottom: 5px;
}

.comments-section {
    margin-top: 20px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.comment-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.comment-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-bottom: 8px;
}

.comment-header-content {
    display: flex;
    flex-direction: column;
}

.comment-username {
    font-weight: bold;
    color: #007bff;
    text-decoration: none;
}

.comment-username:hover {
    text-decoration: underline;
}

.comment-date {
    font-size: 0.875rem;
    color: #888;
}

.comment-body {
    margin-top: 10px;
}

.comment-text {
    font-size: 1rem;
    color: #333;
    line-height: 1.5;
}

.delete-icon {
    position: absolute;
    right: 15px;
    top: 100%; /* Position beneath the date */
    margin-top: 5px;
    padding: 5px 10px;
    cursor: pointer;
    color: black;
    font-size: 1.2rem;
    padding: 5px;
    background-color: red; /* Ensures the icon background is white */
    color: white;
}



.delete-icon:hover {
    background-color: darkred;
}

.notification-banner {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border-radius: 0 0 8px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    width: auto;
    max-width: 90%;
}

.banner-content {
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.banner-progress {
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    background-color: #ffffff;
    transition: width 0.3s linear;
}

.banner-message {
    margin-left: 10px;
    font-size: 1rem;
}

.delete-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001; /* Ensure it appears above everything else */
}

.delete-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
    max-width: 300px;
    width: 100%;
}

.popup-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
}

.popup-button {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

.popup-button.confirm {
    background-color: #dc3545;
    color: white;
    border: none;
}

.popup-button.cancel {
    background-color: #6c757d;
    color: white;
    border: none;
}

.popup-button:hover {
    opacity: 0.8;
}
