.template-selector {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    /* padding: 30px; */
    margin-top: 10px;
    margin-bottom: 30px;
    max-width: 100%;
    box-sizing: border-box;
}

.template-selector select {
    padding: 4px 16px;
    border-radius: 8px; /* Softer rounded corners */
    border: 1px solid #e0e0e0; /* Light border for modern minimalism */
    font-size: 16px; 
    background-color: #f9f9f9; /* Softer background color */
    color: #333; /* Darker text for better contrast */
    flex: 1 1 200px;
    min-width: 160px;
    height: 30px;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    max-width: 100%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

.template-selector select:focus {
    border-color: #007bff; /* Subtle blue border on focus */
    box-shadow: 0 4px 10px rgba(0, 123, 255, 0.2); /* Slight glow effect */
}

.template-selector select:hover {
    background-color: lightgray; /* Blue border on hover */
}


.template-selector button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 20px; /* Adjusted padding for consistency */
    border-radius: 8px; /* Match the select's border radius */
    background-color: #007BFF; /* Primary button color */
    color: white; 
    border: none; 
    cursor: pointer; 
    height: 40px; /* Match the select's height */
    font-size: 16px; 
    box-sizing: border-box; 
    flex: 1 1 200px; 
    max-width: 100%; 
    transition: background-color 0.3s ease, transform 0.3s ease; /* Added transform for hover effect */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
}

/* .template-selector button:hover {
    background-color: #0056b3;
} */

.template-selector button:hover {
    background-color: #0056b3; /* Darker shade for hover state */
    transform: translateY(-2px); /* Slight lift on hover for a modern effect */
}

.template-selector button:focus {
    outline: none; /* Remove default outline */
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Subtle focus ring */
}

.iconGridWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    margin: 5% 0;
    /* margin-left: 17%; */
    /* min-height: 100vh; */
}

/* @media (max-width: 768px) {
    .iconGridWrapper {
        margin: 3% 0;
    }
  } */

/* .iconGridContainer {
    display: inline-flex;
    grid-template-columns: repeat(10, 1%);
    gap: 5px;
    max-width: 800px;
} */

.iconImageWrapper {
    overflow: hidden;
    border-radius: 4px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto; /* Set height to auto to allow flexibility */
    min-height: 20px; /* Ensure a minimum height for consistency */
}

.iconImage {
    width: 100%; /* Ensure the image takes the full width of its wrapper */
    height: auto; /* Maintain aspect ratio by setting height to auto */
    max-width: 100%; /* Ensure the image doesn't exceed its wrapper */
    display: block; /* Removes bottom space below the image */
}

/* Media query for mobile screens */
@media (max-width: 600px) {
    .iconGridContainer {
        grid-template-columns: repeat(10, 1%); /* Size for mobile screens */
    }
}