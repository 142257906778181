.deck-details {
  padding: 20px;
  background-color: #ECE5F0;
  border-radius: 10px;
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.deck-details h2,
.deck-details h3,
.deck-details p {
  margin: 10px 0;
}

.author-link {
  color: black;
  text-decoration: none;
}

.deck-upvotes {
  display: flex;
  align-items: center;
}

.thumbs-up-icon {
  cursor: pointer;
  margin-left: 5px;
}

.thumbs-up-icon.voted {
  color: yellow;
}

.deckImage {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 40px); /* Ensure the image does not exceed the viewport height minus margin */
  /* Ensure the image does not exceed the viewport width */
  /* margin-top: 20px; */
  border-radius: 10px;
  object-fit: contain;
}

/* Responsive styles */
@media (max-width: 768px) {
  .deck-details {
    padding: 10px;
  }

  .deckImage {
    max-height: calc(100vh - 40px);
    margin-left: 0;
    margin-right: 0;
  }
}

.deckDescription {
  text-align: center;
  /* Center the text inside the paragraph */
}

.deckTitle {
  text-align: center;
  /* Center the text inside the paragraph */
}

.deckAuthor {
  text-align: center;
  /* Center the text inside the paragraph */
}