.customCardPage {
  height: 100vh;
  display: flex;
  flex-direction: column;
  touch-action: pan-x pan-y;
  height: 100%
}

@font-face {
  font-family: 'Tharlon';
  src: url("../Tharlon.eot") format("embedded-opentype"),
    url("../Tharlon.woff") format("woff"),
    url('../Tharlon.ttf') format('truetype');
  font-weight: 400;
}

.editor {
  position: relative;
  width: 50%;
  /* height: 70%; */
  /* margin: auto; */
}

@media screen and (max-width: 449px) {
  .editor {
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: 20%;
    -webkit-transform: scale(1.4);
    -moz-transform: scale(1.4);
    -ms-transform: scale(1.4);
    transform: scale(1.4);
  }

  .belowCardContainer {
    margin-top: 15%;
    width: 50%;
    /* margin: auto; */
    align-self: center;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 449px) and (max-width: 549px) {
  .editor {
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: 10%;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }

  .belowCardContainer {
    margin-top: 8%;
    width: 50%;
    /* margin: auto; */
    align-self: center;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 549px) and (max-width: 699px) {
  .editor {
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: 5%;
  }

  .belowCardContainer {
    margin-top: 0%;
    width: 50%;
    /* margin: auto; */
    align-self: center;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 700px) and (max-width: 999px) {
  .editor {
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: -5%;
    -webkit-transform: scale(0.7);
    -moz-transform: scale(0.7);
    -ms-transform: scale(0.7);
    transform: scale(0.7);
  }

  .belowCardContainer {
    margin-top: -10%;
    width: 50%;
    /* margin: auto; */
    align-self: center;
    align-content: center;
    justify-content: center;
  }
}

@media screen and (min-width: 1000px) {
  .editor {
    position: relative;
    width: 50%;
    margin: auto;
    margin-top: -18%;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    transform: scale(0.5);
  }

  .belowCardContainer {
    margin-top: -17%;
    width: 30%;
    /* margin: auto; */
    align-self: center;
    align-content: center;
    justify-content: center;
  }
}

/* @media (max-width: 900px) {
    .editor {
      position: relative;
      width: 50%; 
      margin: auto;
      margin-top: -15%;
      -webkit-transform:scale(0.7);
      -moz-transform:scale(0.7);
      -ms-transform:scale(0.7);
      transform:scale(0.7);
      }
  } */

/* @media (max-width: 1100px) {
    .editor {
      position: relative;
      width: 50%; 
      margin: auto;
      margin-top: -15%;
      -webkit-transform:scale(0.5);
      -moz-transform:scale(0.5);
      -ms-transform:scale(0.5);
      transform:scale(0.5);
      }
  } */


.template-img {
  width: 100%;
  height: 100%;
  /* clip-path: inset(0 0 2% 0); */
}

.card-field {
  position: absolute;
  font-family: 'Poppins', sans-serif;
  color: white;
  /* word-spacing: nowrap;
    display: inline-block; */
}

#card-name-wrapper {
  bottom: 18.5%;
  /* Adjust these values based on your template */
  /* left: 20%; */
  height: 6.5%;
  width: 82%;
  font-size: 3.1vw;
  /* Center text within the element */
  position: absolute;
  /* Ensure positioning relative to parent */
  transform: translateX(-50%);
  /* Center element horizontally by moving it left by half of its width */
  left: 50%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  /* Bold */
  text-transform: uppercase;
  display: 'flex';
  text-align: center;
  align-items: center;
  /* justify-content: 'center'; */
  /* vertical-align: middle; */
  /* text-align: 'center'; */
  /* transform: translateX(-50%); */
  /* Forces text to be uppercase */
}

#card-name {
  /* bottom: 19%; */
  /* Adjust these values based on your template */
  /* left: 20%; */
  width: 100%;
  /* font-size: 3.1vw; */
  /* Center text within the element */
  /* position: absolute; */
  /* Ensure positioning relative to parent */
  /* transform: translateX(-50%); */
  /* Center element horizontally by moving it left by half of its width */
  /* left: 50%; */
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  /* Bold */
  /* text-transform: uppercase; */
  /* Forces text to be uppercase */
  vertical-align: middle;
  /* Regular */
  justify-content: center;
  align-items: center;
  /* position: absolute; */
  /* position: relative; */
  /* height: 10%; */
  display: flex;
  margin: 0;
  padding: 0;
}

#card-name-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  /* Optional: if you want to center horizontally as well */
}

/* Child element */
#card-name {
  width: 100%;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  vertical-align: middle;
  /* This can be removed as it's not needed with flexbox */
  justify-content: center;
  /* This can be removed as it's not needed with flexbox */
  align-items: center;
  /* This can be removed as it's not needed with flexbox */
}

#energy-cost {
  top: 0.75%;
  left: 0%;
  width: 15%;
  height: 10.5%;
  align-content: center;
  justify-content: center;
  font-size: 5vw;
  text-align: center;
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  font-style: italic;
  paint-order: stroke fill;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke: 8px black;
}

@media (max-width: 768px) {
  #energy-cost {
    -webkit-text-stroke: 4px black;
  }
}

#power {
  top: 12.5%;
  left: 0%;
  width: 15%;
  height: 10.5%;
  align-content: center;
  justify-content: center;
  font-size: 5vw;
  text-align: center;
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  /* Bold */
  font-style: italic;
  paint-order: stroke fill;
  -webkit-text-fill-color: white; /* Will override color (regardless of order) */
  -webkit-text-stroke: 8px black;
  /* Create the outline effect */
}

@media (max-width: 768px) {
  #power {
    -webkit-text-stroke: 4px black;
  }
}

#ability-name {
  bottom: 13%;
  left: 20%;
  width: 60%;
  font-size: 2.35vw;
  text-align: center;
  font-family: 'Cabin', sans-serif;
  font-weight: 700;
  /* Bold */
  color: #323232;
}

#ability-desc-holder {
  top: 86%;
  /* left: 2%; */
  width: 100%;
  height: 12.1%;
  /* position: relative; */
  /* clear: both; */
  position: absolute;
  display: flex;
  align-items: center;
}

#ability-description {
  /* top: 87.5%; */
  left: 4.5%;
  width: 91%;
  font-size: 3vw;
  /* text-align: center; */
  vertical-align: middle;
  font-family: 'Cabin', sans-serif;
  font-weight: 400;
  /* Regular */
  justify-content: center;
  align-items: center;
  color: #323232;
  /* position: absolute; */
  /* position: relative; */
  /* height: 10%; */
  display: flex;
  margin: 0 auto;
  padding: 0;
  /* display: inline; */
  /* align-items: center; */
  /* white-space: pre-wrap; */
  /* vertical-align: middle; */
}

#ability-description table {
  width: 80%;
  /* Adjust as necessary for desired table width */
  border-collapse: collapse;
  margin: 0 auto;
  /* Ensures table is centered within the span */
}

/* #ability-description td {
    padding: 5px;
  } */

#ability-description td:first-child {
  width: 10%;
  /* Adjust as needed for the image cell */
}

.cellImg {
  vertical-align: top;
  position: relative;
}

.cellImg img {
  /* margin-top: 1%; */
  vertical-align: baseline;
  /* margin-left: auto;
  margin-right: auto;
  margin-bottom: auto; */
}

.inline-icon .hoverable {
  color: red;
}

#ability-description img {
  vertical-align: top;
  /* Align images to the top of the cell */
}

.inline-icon {
  vertical-align: top;
}


#card-code {
  bottom: 26.75%;
  left: 72.5%;
  width: 20%;
  font-size: 2vw;
  text-align: center;
  font-family: 'Tharlon', sans-serif;
  color: white;
}

.scale-hidden {
  /* display: none; */
  /* z-index: -99; */
  transform: scale(1) !important;
  /* Ensure it overrides existing styles */
}

.page-specific {
  touch-action: pan-x pan-y;
  height: 100%;
}

/* Modal Background */
#abilityModal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

#confirmModal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

#helpModal {
  display: none;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
}

/* Modal Content */
#abilityModal .modal-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  display: flex;
  /* Make the content a flex container */
  flex-direction: column;
  /* Keep buttons in a vertical column */
  align-items: center;
  /* Center the buttons horizontally */
  position: relative;
  /* Add this line */
}

#confirmModal .modal-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  display: flex;
  /* Make the content a flex container */
  flex-direction: column;
  /* Keep buttons in a vertical column */
  align-items: center;
  /* Center the buttons horizontally */
  position: relative;
  /* Add this line */
}

#helpModal .modal-content {
  background-color: #fff;
  margin: 10% auto;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  max-height: 80vh; /* Limit the height of the content to 80% of the viewport */
  overflow-y: auto; /* Enable vertical scrolling when content overflows */
}

#helpModal .modal-content h2 {
  text-decoration: underline;
}


/* Close Button */
.closeab {
  position: absolute;
  /* Add this line */
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 30px;
  cursor: pointer;
}

.closeHelp {
  position: absolute;
  /* Add this line */
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 30px;
  cursor: pointer;
}

.closeabconfirm {
  position: absolute;
  /* Add this line */
  top: 10px;
  /* Adjust top position as needed */
  right: 10px;
  /* Adjust right position as needed */
  font-size: 30px;
  cursor: pointer;
}

/* Ability Buttons */
.ability-option {
  display: inline-block;
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  font-size: 16px;
  cursor: pointer;
}

#add-ability-button {
  background-color: #027bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
}

/* #add-ability-button {
  background: linear-gradient(to right, #007bff, #0062cc);
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
} */

#add-ability-button:hover {
  background-color: #0062cc;
}

.boldButton {
  font-weight: bold;
  font-size: 16px;
  padding: 16px 10px;
  cursor: pointer;
  background-color: #f0f0f0;
  /* Match your icon background if needed */
  border: none;
  /* border-radius: 4px; */
}

.boldButton:hover {
  background-color: #d3d3d3;
  /* Hover effect */
}

.boldButton.active {
  background-color: #d3d3d3;
}

.boldButton .boldActive {
  color: #000;
  text-shadow:
    5px 0 currentColor,
    -5px 0 currentColor,
    0 5px currentColor,
    0 -5px currentColor;
  transform: scale(1.2);
}

.hoverable {
  width: 100%;
  height: auto;
  transition: opacity 0.3s;
}

.cellImg:hover .hoverable {
  filter: opacity(0.3) grayscale(100%) brightness(50%);
  /* Adjust opacity and grayscale */
}

/* .cellImg:hover {
  background-color: rgba(255, 0, 71, 0.6);
  position: relative;
} */


.cellImg:hover::before {
  content: 'REMOVE';
  /* Black minus sign */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -70%);
  font-size: 20px;
  color: red;
  z-index: 1;
}

.belowCardContainer {
  position: relative;
  display: block;
  align-items: center;
  justify-content: center;
}

#add-ability-button {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 auto;
}