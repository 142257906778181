.grid-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1vw;
  /* Adjust the gap between items as needed */
  margin: 0 auto;
  /* Center the grid container */
  width: 90vw;
  /* Set the grid container width to 90% of the viewport width */
}

/* Media query for mobile screens */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(3, 1fr);
    /* Change to 3 columns for mobile screens */
  }
}

.grid-item {
  background-color: #f0f0f0;
  /* Example background color */
  border: 1px solid #ccc;
  /* Example border */
  padding: 0.5vw;
  /* Reduced padding */
  text-align: center;
  /* Center text */
}

.grid-item img {
  max-width: 100%;
  /* Make sure the image fits within its container */
  height: auto;
  /* Maintain the aspect ratio */
  max-height: 40vh;
  /* Maximum height for the image */
}

.grid-item a {
  color: inherit;
  /* Inherit text color */
  text-decoration: none;
  /* Remove underline */
}

.grid-item a:hover {
  color: inherit;
  /* Maintain text color on hover */
  text-decoration: none;
  /* Maintain no underline on hover */
}


.delete-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001; /* Ensure it appears above everything else */
}

.delete-popup {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 300px;
  width: 100%;
}

.popup-buttons {
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.popup-button {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.popup-button.confirm {
  background-color: #dc3545;
  color: white;
  border: none;
}

.popup-button.cancel {
  background-color: #6c757d;
  color: white;
  border: none;
}

.popup-button:hover {
  opacity: 0.8;
}