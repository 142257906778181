header {
  background: #333;
}

/*
header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo-holder {
  padding: 0 2rem;

}

#logo {
  width: 100px;
  margin-top: auto;
  margin-bottom: auto;
}

.home-name-holder {
  padding: 0 2rem;
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
} */

header .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.logo-name-holder {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* Remove underline from the link */
  /* padding: 0 2rem; */
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

.menuOption {
  display: flex;
  align-items: center;
  text-decoration: none;
  /* Remove underline from the link */
  /* padding: 0 2rem; */
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
}

#logo {
  width: 100px;
  margin-right: 1rem;
}

@media (max-width: 999px) {

  /* Adjust breakpoint as needed */
  #logo {
    margin-right: -0.2rem;
  }
}

@media (max-width: 999px) {

  /* Adjust breakpoint as needed */
  .logoText {
    display: none;
  }
}

h1 {
  margin: 0;
  font-size: 2rem;
  color: inherit;
}

@media (max-width: 999px) {
  /* Adjust breakpoint as needed */
  h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 500px) {
  /* Adjust breakpoint as needed */
  h2 {
    font-size: 1.2rem;
  }
}

@media (max-width: 420px) {
  /* Adjust breakpoint as needed */
  h2 {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  h1 {
    font-size: 1.4rem;
    /* Change to 3 columns for mobile screens */
  }
}


.headerText {
  font-size: .9rem;
  color: white;
  text-align: center;
  padding: 0 2rem;
}


.headerText:hover {
  cursor: pointer;
}

header a {
  color: white;
  text-decoration: none;
}

.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

#signIn {
  font-size: .9rem;
  color: white;
  text-align: center;
  /* padding: 0 2rem; */
  padding-right: 25px;
  cursor: pointer;
  margin-top: auto;
  margin-bottom: auto;
}