/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #ECE5F0;
  margin: 0;
  font-family: "Poppins";
  height: 100vh;
  font-size: 100%;
  line-height: 1.2;
}

html, body {
  overflow-x: hidden; /* Prevent horizontal scrolling */
  margin: 0; /* Remove default margin */
  padding: 0; /* Remove default padding */
}