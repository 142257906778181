.custom-grid-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.custom-grid-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 0 10px;
    /* Adjust as needed */
}

.custom-grid-item {
    background-color: #f0f0f0;
    /* Example background color */
    border: 1px solid #ccc;
    /* Example border */
    padding: 0.5vw;
    /* Reduced padding */
    text-align: center;
    /* Center text */
}

.custom-deck-image {
    max-width: 100%;
    /* Make sure the image fits within its container */
    height: auto;
    /* Maintain the aspect ratio */
    max-height: 40vh;
    /* Maximum height for the image */
}

.custom-grid-item a {
    color: inherit;
    /* Inherit text color */
    text-decoration: none;
    /* Remove underline */
}

.custom-grid-item a:hover {
    color: inherit;
    /* Maintain text color on hover */
    text-decoration: none;
    /* Maintain no underline on hover */
}

.deck-info {
    text-align: center; /* Center text inside the container */
}

.deck-upvotes {
    display: flex;
    justify-content: center; /* Center contents horizontally */
    align-items: center; /* Center contents vertically */
    margin: 10px 0; /* Add some margin for spacing */
}

.custom-delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.custom-delete-button:hover {
    background-color: darkred;
}

.delete-button {
    background-color: red;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}

.delete-button:hover {
    background-color: darkred;
}